import '../index.css';
import { ReactComponent as Logo } from '../Images/ampx.svg';


function Footer() {
  return (
    <div className="w-full bg-lightgrey flex items-center justify-center text-xs">
      <div className="w-full md:w-4/5 py-6 md:py-20 px-4 md:px-0 text-mediumgrey flex items-center justify-center justify-items-center md:justify-between">
        <div className="flex items-center">
        <a className="hover:opacity-70 mr-2" href="https://ampx.energy/" target="_blank"><Logo width="70px" height="17.6px"/></a>
        <span>Copyright © 2021</span>
        </div>
        <div className="w-2/5 flex justify-end invisible md:visible">
          <a className="mr-4 hover:opacity-70" href="Alice_user_manual.pdf" download>Download Manual</a>
          <a className="mr-4 hover:opacity-70" href="/terms">Terms</a>
          {/* <a className="hover:opacity-70" href="#">Imprint</a> */}
          <a className="mr-4 hover:opacity-70" href="/privacy">Privacy Policy</a>
          {/* <a className="hover:opacity-70" href="#">Press</a>  */}
          <a className="hover:opacity-70" href="mailto: support@alice.energy">Contact us</a>
          {/* <a className="hover:opacity-70" href="#">Become a Partner</a> */}
        </div>          
      </div>
    </div>
  );
}

export default Footer;
