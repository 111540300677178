import '../index.css';

function SupportedBrands() {
  return (
    <div className="bg-white py-10 md:py-20 px-8 md:px-4 md:px-0 text-black flex flex-col items-center justify-center">
      <div className="text-l text-center font-bold">Supported brands</div>
      <div className="text-l text-center font-thin">We connect all your devices to make your home even smarter</div>
      <div className="w.full md:w-4/5 mt-2 md:mt-10 flex items-center justify-around overflow-hidden">
        <img className="w-full hidden md:block greyscale opacity-50" src="../Images/supported-brands-desktop.png" />
        <img className="w-full visible md:hidden greyscale opacity-50" src="../Images/supported-brands-mobile.png" />
      </div>       
    </div>
  );
}

export default SupportedBrands;
