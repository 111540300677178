import '../index.css';

function TopMenu() {
  return (
      <div className="w-3/5 flex justify-around underline-offset hidden md:flex">
        <a className="hover:opacity-70" href="/download">Download App</a>
        <a className="hover:opacity-70" href="https://drive.google.com/file/d/1ltIGSZKAhsAquqjBiwcAsKTQRLhh8nJD/view?usp=sharing" target="_blank">User Manual</a>
        {/* <a className="hover:opacity-70" download href="Downloads/Alice-user-manual.pdf">User Manual</a> */}
        <a className="hover:opacity-70" target="_blank" href="https://ampx.energy/">Amp X</a>
        <a className="hover:opacity-70" href="/troubleshooting">Troubleshooting</a>
        <a className="hover:opacity-70" href="./#faq">FAQ</a>
        <a className="hover:opacity-70" href="mailto: support@alice.energy">Contact us</a>
      </div>
  );
}

export default TopMenu;
