import { ReactComponent as Logo } from './../Images/alice.svg';
import '../index.css';
import PrimaryButton from './PrimaryButton';
import TopMenu from './TopMenu';
import MobileMenu from './MobileMenu';


function Hero() {
  return (
    <div>
      <MobileMenu />
      <div className="hero bg-black bg-cover text-white flex flex-col items-center justify-center text-center p-8">   
      <a className="hover:opacity-70" href="./"><Logo className="hidden md:block" width="200px"/></a>
      <a className="hover:opacity-70" href="./"><Logo className="block md:hidden" height="120px"/></a>
        <TopMenu />
        <div className="text-xxl md:text-4xl text-center font-bold leading-hero md:leading-loose  mt-4 md:mt-0">Your home, <bd className="block md:hidden"/>on autopilot.</div>
        <div className="text-base md:text-l mt-2 md:mt-0">Pay less for charging your electric vehicle and optimise your home. 
          <br className="hidden md:block" /> Alice helps you use energy when it’s cheapest and greenest, effortlessly.
        </div>
        <span className="pb-4 md:pb-0"><a href="#benefits"><PrimaryButton /></a></span>
      </div>
    </div>
  );
}

export default Hero;
