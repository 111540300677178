import '../index.css';

function SavingsCharts() {
  return (
    <div className="bg-lightgrey py-10 md:py-20 text-black flex flex-col items-center justify-center text-left">
      <div className="text-xl md:text-xxl text-center font-thin leading-tight md:leading-none px-8">Easy peasy savings squeezy</div>
      <div className="w-full md:w-3/4 text-base md:text-l text-center font-thin mt-2 px-8">
      While most energy bills charge a flat fee, more and more energy companies are introducing variable tariffs. With variable tariffs, the cost of energy changes throughout the day, making it much cheaper for you to use energy at certain times of the day. However, it can be difficult to track when energy is green and cheap. Thanks to Alice you can now take advantage of the best your tariff has to offer, with zero effort on your side.
      </div>

      <div className="w-full md:w-4/5 mt-10 mx-8 md:mx-0 md:mt-20 flex items-center justify-start md:justify-around horizontal-scroll">
        <img className="w-4/5 md:w-1/2 ml-8 mr-2 md:mx-4" src="../Images/best-slots.png" />
        <img className="w-4/5 md:w-1/2 ml-2 mr-4 md:mx-4" src="../Images/with-alice.png" />
      </div>

      <div className="text-xs text-center font-thin mt-6">
        *Based on avg customer on Octopus Time-of-Use Tariff<br />
        **EV Owner can expect to save even more on their charge<br />
        Participation in Demand Response programs may result in additional savings
      </div>
            
    </div>
  );
}

export default SavingsCharts;
