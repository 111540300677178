import React from 'react';
import '../index.css';
import { ReactComponent as Logo } from '../Images/alice-white.svg';
import { slide as Menu } from 'react-burger-menu';

class MobileMenu extends React.Component {
  showSettings (event) {
    event.preventDefault();

  }

  render () {
    return (
        <Menu right width={ '80%' } customBurgerIcon={ <img src="../Images/hamburgericon.svg" className="visible md:invisible"/> }>
          <Logo width="80px" height="51px"/>
          <a className="menu-item" href="./">Home</a>
          <a className="menu-item" href="/download">Download App</a>
          <a className="menu-item" target="_blank" href="https://ampx.energy/">Amp X</a>
          <a className="menu-item" href="#faq">FAQ</a>
          <a className="menu-item" href="/troubleshooting">Troubleshooting</a>
          <a className="menu-item" href="https://drive.google.com/file/d/1ltIGSZKAhsAquqjBiwcAsKTQRLhh8nJD/view?usp=sharing" target="_blank">User Manual</a>
          <a className="menu-item" href="/privacy">Privacy Policy</a>
          <a className="menu-item" href="/terms">Terms</a>
          <a className="menu-item" href="mailto: support@alice.energy">Contact us</a>
        </Menu>
    );
  }
}

export default MobileMenu;
