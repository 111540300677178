import './index.css';
import Header from './Components/Header';
import TroubleshootingContent from './Components/TroubleshootingContent';


function Troubleshooting() {
  return (
    <div className="overflow-x-hidden flex flex-col">
      <Header />
      <div className="mb-40"><TroubleshootingContent/></div>
    </div>

  );
}

export default Troubleshooting;
