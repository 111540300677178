import '../index.css';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';


function FAQ() {
  return (
    <div className="flex flex-col items-center justify-center text-xs px-8 md:px-2">
      <div id="faq" className="text-xl md:text-xxl text-center font-thin">Got questions?</div>
      <span className="w-full md:w-4/5 text-l font-bold p-2 text-left mt-2">How it works</span>
      <Accordion className="w-full md:w-4/5" allowZeroExpanded="true">
      <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How does Alice work?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Alice schedules your appliances, heating and electric vehicle charging at the times of day when energy is cheapest and greenest. She is connected to the internet which allows her to predict when renewable energy will be available, and what the price of that energy will be. She also has the ability to understand your preferences and learn about your energy usage patterns, meaning that Alice can figure out and automate the optimal schedule. You will receive a notification when a task is completed or when changes to the schedule have been made.                    </p>
                </AccordionItemPanel>
            </AccordionItem>
      <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How does Alice learn my energy usage patterns?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Alice combines an understanding of how household appliances work with your energy usage patterns. Over time this will allow her to determine how you tend to run your house.                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        How much can I save on my energy bill by using Alice?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Savings can range from tens to hundreds of pounds per year depending on a number of factors.  For example, if you have an electric car that you charge at home or your heating is electric, your savings are likely to be much higher.  We expect average Alice users to be able to reduce their energy bills by more than 10%, with some users saving as much as 30%.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                       How do you calculate my savings?  
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Alice works best with a variable or time-of-use tariff. When you are on a variable tariff, the price you pay your supplier for each unit of energy can go up and down at different times of the day. This is great because it means you can take advantage of cheaper energy, but it can get tricky to plan when to use your home energy accordingly.<br></br>During the Living Lab Trial, savings are going to be calculated by subtracting what you would have normally paid on your tariff without Alice, minus the cost of running your home devices using Alice. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What is a Smart Variable Tariff?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Variable-rate tariffs (also called variable price plans) do exactly what their name suggests – they vary. Or, more precisely, the cost per unit of energy (kWh) varies. 
                    But they don’t go up and down randomly – they “track” wholesale energy prices. So if wholesale prices rise or fall, your variable energy rates may change too. But not necessarily. And here’s the crux of the matter. On a variable tariff, your supplier has the right to change the unit price at their discretion. 
                    And wholesale prices fluctuate. A lot. But don’t despair – your rate won’t change nearly so quickly. And by law, your supplier has to warn you when your prices are going to change. This is usually around 30 days before.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        How much can I reduce my carbon intensity by using Alice?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Calculating the effective carbon emissions of a single home is no easy task, but our ability to calculate this carbon intensity dynamically and accurately is improving over time as we refine our models and gather more relevant data.  Our estimate is that an average household can reduce its carbon emissions by more than 50Kg of CO2 in a year, which would be a reduction of more than 10%.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How does Alice help you use more renewables?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    The energy that you use in your house can come from many different sources - in the middle of the day it may come from solar panels, and in the middle of the night it may come from wind turbines. Because it is expensive to store electricity it could be really beneficial if we all used more energy when renewables are available and less when they are not. Alice can help you do this by scheduling your laundry or electric vehicle charging to occur during times where there is excess renewable energy.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            </Accordion>


        <span className="w-full md:w-4/5 text-l font-bold p-2 text-left mt-2">Availability, installation and safety</span>
        <Accordion className="w-full md:w-4/5" allowZeroExpanded="true">
            <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        When will Alice be available to the general public?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Alice is currently being trialled through the Energy Systems Catapult's Living Lab program.  We expect to be able to offer Alice to the general public within months of completing the trial and demonstrating what Alice can do.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Who can participate in Alice trials?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Currently a household can only participate in the Alice trial if it is a member of the Living Lab cohort, but we may run a follow-up trial where most households in the UK are eligible to participate.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                      Does Alice require installation from a certified technician?  
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        No.  Setting up the Alice hub in your house is similar to setting up a new router.  The most challenging part of the process is the placement of a current clamp near your electricity meter, but we will provide you with step-by-step instructions on how to do it, and our technical support team will always be available to help you with this. You can find all the safety instructions in the manual.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Are my devices safe?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Alice has been designed to run your devices under normal operating conditions, ensuring that they are safe. You have the ability to take full control of the devices at any time, by overriding Alice’s proposed usage schedule. You can also suspend Alice control of your connected devices any time you wish for as long as you wish. The Alice hub, smart plugs and current clamp are electronic devices and care us to be taken when utilising them. You can find all the safety instructions in the manual.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Are my data safe? Should I be concerned about my privacy?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        We care a great deal about the safety of your data and your privacy. Alice is aligned with the current GDPR regulations and we are taking all necessary steps to ensure the maximum level of security.<br></br>
                        Data collected by the smart plugs is stored within the Alice hub, which will allow Alice to learn about your habits and proposing the best schedule. A subset of this data is anonymised and sent to our secure cloud using end-to-end encrypted communication. We will store this subset of data securely and dispose of it once the trial is complete. If at any time you revoke our permission to handle your data, we will immediately and securely dispose of any data you or Alice have provided us.                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            </Accordion>


        <span className="w-full md:w-4/5 text-l font-bold p-2 text-left mt-2">Compatibility</span>
        <Accordion className="w-full md:w-4/5" allowZeroExpanded="true">
            <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            What electric vehicle brands are compatible? 
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            For electric vehicle integration we are partnering with Smartcar. To see the full list of supported electric vehicle brands and models please visit <a href="https://smartcar.com/product/compatible-vehicles/" target="_blank">https://smartcar.com/product/compatible-vehicles/</a>
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        What will happen when I boost my electric vehicle charge?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Alice asks you for specific minimum and target levels of charge and the time you prefer your electric vehicle to be charged by. However, you will always have the possibility to boost your charge, even when the charge schedule has already started. When you boost, your car will charge up to the maximum charge threshold allowed by your car as soon as possible, regardless of the preferences you’ve expressed to Alice or current energy prices.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Will I still be able to use my electric vehicle app?  
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Of course! Smartcar delegates control to Alice for specific tasks such as the ability to start, stop and pause charging, but you can always access any other car feature from your Tesla app as usual.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Can there be conflicts between the charge scheduled by Alice and the charge scheduled by my electric vehicle or my home charger?                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        If your electric vehicle or home charger are scheduling charges, there is the possibility of conflicts with charges scheduled by Alice. For this reason, before your first charge, we advise to disable any charge scheduling which is active either in your electric vehicle or home charger. Alice will always try to schedule the charge at the cheapest possible times, taking into account your preferences. You can always edit the charge schedule parameters at any time.                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        I have no smart appliances in my home.  Can I still use Alice? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Yes.  The Alice hub comes with smart plugs that you will have to link to the hub and use for certain appliances in your household.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        What traditional appliances are compatible?  
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Alice supports a variety of category of non-smart home appliances such as washing machines, tumble dryers, dishwashers, pool pumps and more via smart plug. However, you will have to check that your appliance is able to resume the cycle when the smart plug is turned off and turned back on again.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        What smart appliances are compatible?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Alice will support a variety of categories of non-smart home appliances, such as Tado thermostats, washing machines, tumble dryers, dishwashers, pool pumps and more via a service called IFTTT. Check out more about <a href="https://ifttt.com/home" target="_blank">IFTTT</a>. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What if Alice cannot recognise the smart features of my smart appliance?  
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Even though Alice works best when she can make use of sensing and control features of your smart appliances, she can still carry out her work without them.  Regardless of whether or not the appliance is smart, we recommend putting smart plugs on all appliances that you would like Alice to manage for you. In case Alice is not able to control your smart appliance, it will nonetheless be able to monitor its power consumption, and advise you on best times of use.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        I have my own smart plugs.  Can I use these with Alice? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Currently only the smart plugs included with the Alice hub can be used. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        Is Alice compatible with Alexa, Apple home or Google home? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Unfortunately not at the moment. Keep an eye on this page for future updates. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>

        <span className="w-full md:w-4/5 text-l font-bold p-2 text-left mt-2">How to use it</span>
        <Accordion className="w-full md:w-4/5" allowZeroExpanded="true">
        <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        How does Alice schedule appliances?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p> Please have a look at the <a href="https://youtu.be/MpCgFyGjC5w" target="_blank">video tutorial here</a>
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
            <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Can I change device schedule if I need to?
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Each time you start an appliance, Alice will propose a schedule according to your preferences, your app preferences and the energy price of your tariff. However, you can always edit individual tasks or your device preferences as desired.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How to suspend Alice control of all or specific devices?  
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <span>
                    <p>
                        Yes you can! Simply go to the bottom bar of Alice app, click on the devices icon <img className="inline" width="20px" src="../Images/device-icon.png" /> , select the specific device you want to suspend, and suspend or resume Alice control via the “monitor-only” switch at the bottom of the page at any time.  If you want to suspend Alice control of all devices, you can find the global suspend switch by clicking on the settings icon <img className="inline" width="20px" src="../Images/settings.png" /> and scrolling to the bottom of the page.
                    </p>
                    </span>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        How can I add multiple users to my household?  
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    When you first setup Alice in the mobile application, you will register as the primary user for the household. Multiple users can download the app and register by scanning the QR code or typing in the serial number on the Alice hub.                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        What if I am not at home? Will I be able to control my home devices?  
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        Alice initiates a device task only after you have interacted with your device at home, for example after you’ve loaded your washing machine and set the wash program. Alice is not a smart-home hub and does not have remote control functionalities. However, if a task is already scheduled, you can use Alice to pause, resume or suspend Alice control temporarily, even when you are outside your home.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How can I change my tariff?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Alice uses your tariff to know the cost of your energy, to plan when best use your home devices and to calculate savings. You can change your tariff from the Alice app by going to Settings > My Tariff. You can select from a list of known tariffs or choose a generic flat tariff. If your tariff is missing, please, select the “I don’t know my tariff” option, or contact <a className="hover:opacity-70 font-bold" href="mailto: support@alice.energy">here</a></p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What do I need to do if I change my home WiFi?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        If you replaced your WiFi password or your home wifi router you will need to connect the Alice hub to your home network again by following the installation guide as contained on the manual provided with the Alice hub. You can also access the installation process from the Alice app by visiting Settings > Connected Hardware > Alice hub > WiFi Network > Change WiFi Network 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>

    <span className="w-full md:w-4/5 text-l font-bold p-2 text-left mt-2">Faults and disposal</span>
      <Accordion className="w-full md:w-4/5 mb-4 md:mb-10" allowZeroExpanded="true">
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        What if my phone breaks? Will I still be able to control my home devices?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        For Alice to work correctly you need to pair the Alice hub to the Alice app on your mobile device. If your device breaks you can disable Alice control of all appliances by tapping the button on the Alice hub twice.  When control is disabled, The LED light on the hub will turn white, and any smart plugs that have been switched off by ALICE will be switched back on.  If you wish, you can also tap the button on the Alice hub once to switch on any smart plugs that have been switched off by Alice, and unplug Alice hub from the electricity until you are able to pair it to the Alice app again.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        How do I replace a smart plug?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        If a Smart Plug connected to your home device breaks and you need to replace it, you can do it so by following these steps:
                        <ul>
                            <li>On the Alice app go to the device list</li>
                            <li>Select the device assigned to the smart plug you want to replace</li>
                            <li>Tap on the “Device Connection” item at the bottom of the page</li>
                            <li>Tap on Replace smart plug and follow the instructions on screen</li>
                        </ul>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        How do I dispose of the Alice hub, current clamp and smart plugs?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        We would advise you to send the Alice hub and any associated hardware back to us so that we can dispose of it responsibly.<br></br>
                        If you would rather dispose of it yourself, do not dispose of electrical appliances as unsorted municipal waste; use separate collection facilities. Contact your local authority for information regarding the collection systems available. If electrical appliances are disposed of in landfills or dumps, hazardous substances can leak into the groundwater and get into the food chain, damaging your health and well-being. When replacing old appliances with new ones, the retailer is legally obliged to take back your old appliance for disposal at least free of charge.
                    </p>
                </AccordionItemPanel>
            </AccordionItem> 
        </Accordion>
    </div>
  );
}

export default FAQ;
