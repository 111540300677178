import '../index.css';

function Benefits() {
  return (
    <div className="w-full bg-lightgrey px-4 md:px-0 py-10 md:py-20 text-black flex flex-col items-center justify-center text-left">
      <div id="benefits" className="text-xl md:text-xxl text-center font-thin leading-tight md:leading-none">Alice makes your home smarter and greener.</div>
      <div className="w-full md:w-4/5 mt-10 mx-8 md:mx-0 md:mt-20 flex items-center justify-start md:justify-around horizontal-scroll">
        <div className="w-4/5 md:w-1/3 md:mx-4 benefits-card bg-ev-1">
          <div className="max-w-full padded-multiline text-base md:text-l font-bold"><h2><span>With Alice smart charging<br />you pay less, while<br />getting more miles</span></h2></div>
        </div>
        <div className="w-4/5 md:w-1/3 md:mx-4 benefits-card mx-12 bg-dish-1">
          <div className="max-w-full padded-multiline text-base md:text-l font-bold"><h2><span>Alice automates your<br />appliances so you<br />save money and reduce<br />your carbon footprint</span></h2></div>
        </div>
        <div className="w-4/5 md:w-1/3 md:mx-4 benefits-card bg-tado-1">
          <div className="max-w-full padded-multiline text-base md:text-l font-bold"><h2><span>More comfort, ease of use<br />and extra savings for your<br />smart thermostat</span></h2></div>
        </div>
        <div className="visible block flex md:invisible w-2"></div>
      </div>      
    </div>
  );
}

export default Benefits;
