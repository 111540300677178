import './index.css';
import Home from './Home';
import Download from './Download';
import Privacy from './Privacy';
import Terms from './Terms';
import Troubleshooting from './Troubleshooting';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";



function App() {
  return (
    <Router>
      <div className="overflow-x-hidden">
      <Switch>
          <Route path="/" exact component={() => <Home />} />
          <Route path="/Download" exact component={() => <Download />} />
          <Route path="/Privacy" exact component={() => <Privacy />} />
          <Route path="/Terms" exact component={() => <Terms />} />
          <Route path="/Troubleshooting" exact component={() => <Troubleshooting />} />
        </Switch>
      </div>
    </Router>

  );
}

export default App;
