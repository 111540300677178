import './index.css';
import Header from './Components/Header';


function Terms() {
  return (
    <div className="overflow-x-hidden flex flex-col">
      <Header />
      <div className="mx-4 mb-20 md:mx-40">
      <div className="text-left text-xl font-bold mt-20 mb-10">Terms &#38; Conditions</div>

    <p>This Privacy Notice (<strong>“Notice”</strong>) describes how <strong>Amp X Limited</strong> (also referred to as “AmpX”, “we”, or “us”) will make use of your personal information that we collect as part of our Living Lab trial. The Notice contains the following sections:</p>
    <br />
    <ul id="sections" class="list-decimal list-inside">
        <li><a href="#what-does-this-notice-cover">What does this Notice cover</a></li>
        <li><a href="#personal-information-we-collect">Personal information we collect</a></li>
        <li><a href="#how-and-why-we-use-your-personal-information">How and why we use your personal information</a></li>
        <li><a href="#storing-your-personal-information">Storing your personal information</a></li>
        <li><a href="#sharing-your-personal-information">Sharing your personal information</a></li>
        <li><a href="#your-data-protection-rights">Your data protection rights</a></li>
        <li><a href="#updates-to-this-notice">Updates to this Notice</a></li>
        <li><a href="#contact-us">Contact us</a></li>
    </ul>

    <section id="what-does-this-notice-cover" class="mt-10">
        <h3 class="font-bold text-l mb-4">1. What does this Notice cover</h3>
        <p>This Notice describes how Amp X Limited (also referred to as “AmpX”, “we”, or “us”) will make use of your personal information that we collect as part of our trial with the Living Lab project.</p>
        <br />
        <p>It explains the types of information we collect, the purposes for which we are using personal information and the legal grounds on which we rely. Also, the Notice sets out how we share personal information with third parties and for how long we store personal information.</p>
        <br />
        <p>It also describes your data protection rights, including the right to object to some or all of the processing which we carry out. More information about your rights, and how to exercise them, is set out in the “Your data protection rights” section.</p>
    </section>

    <section id="personal-information-we-collect" class="mt-10">
        <h3 class="font-bold text-l mb-4">2. Personal information we collect</h3>
        <p>When we refer to <em>“personal information”</em> or <em>“personal data”</em> in this Notice, we mean information that relates to you. If you are selected to participate in our trial, we will collect and process the following information about you:</p>
        <br />
        <table>
            <tr>
                <th>Types of information</th>
                <th>How we collect the information</th>
            </tr>
            <tr>
                <td><strong>Identity and contact information,</strong> including your name, your postal address, email address and phone number.</td>
                <td>Directly from you when you sign up to participate in our trial.</td>
            </tr>
            <tr>
                <td><strong>Household information,</strong> including number of residents in your household, floor plans and electrical appliances used.</td>
                <td>Directly from you when you sign up to participate in our trial and when you customize your app.</td>
            </tr>
            <tr>
                <td><strong>Energy consumption information,</strong> including how and when you use your appliances, how much energy you consume and your supplier tariff information.</td>
                <td> Through our devices that we use in your household, such as our behind the meter box (‘Alice hub’), the clamp and smart plugs; also, through our app.<br /><br />Energy Systems Catapult Limited (the ‘Catapult’), which is running the Living Lab project and assists us with our trial, will also share with us information collected through the sensors they use in your household.</td>
            </tr>
            <tr>
                <td><strong>App usage information,</strong> including app registration information and information on how you use our app, for example, whether you follow or override the app’s recommendations, how you customize the app and schedule your appliances through the app.</td>
                <td>Through our app.</td>
            </tr>
            <tr>
                <td><strong>Your feedback,</strong> provided through questionnaires, surveys and meeting sessions</td>
                <td>Directly from you and through Catapult, where they carry out meeting sessions and administer surveys on our behalf.</td>
            </tr>
            <tr>
                <td><strong>Technical information,</strong> including information related to the device you use to access our app, including your IP address and device ID.</td>
                <td>Through our app.</td>
            </tr>
        </table>
    </section>

    <section id="how-and-why-we-use-your-personal-information" class="mt-10">
        <h3 class="font-bold text-l mb-4">3. How and why we use your personal information</h3>
        <p>In this section we explain for which purposes we collect and use your personal information and on which grounds we rely under data protection law to use such information.</p>
        <br />
        <table>
            <tr>
                <th>Purpose</th>
                <th>Legal grounds</th>
            </tr>
            <tr>
                <td>
                    <strong>Performance of the trial:</strong> To carry out the trial in which you participate, and more specifically to:
                    <ul>
                        <li>Register you in our trial;</li>
                        <li>Measure your energy consumption and create a consumption profile for your household;</li>
                        <li>Analyse your energy consumption and make recommendations/decisions in relation to the way you consume energy and you use your appliances, in order to optimize your energy consumption.</li>
                    </ul>
                </td>
                <td>Your <strong>consent.</strong></td>
            </tr>
            <tr>
                <td><strong>Manage your participation in the trial,</strong> for example, to communicate with you to resolve any queries you may have.</td>
                <td>Our <strong>legitimate interests</strong> to effectively manage the trial.</td>
            </tr>
            <tr>
                <td><strong>Feedback/ performance assessment:</strong> To carry out surveys and collect your feedback, in order to assess the performance of the trial and if necessary to make adjustments/ improvements to our project.</td>
                <td>Our <strong>legitimate interest</strong> to seek feedback and improve our trial.</td>
            </tr>
            <tr>
                <td><strong>Information security & IT support:</strong> to protect the security of our systems, devices and apps and to resolve any technical issues.</td>
                <td>Our <strong>legitimate interest</strong> to protect the security and effective functioning of our information systems.</td>
            </tr>
            <tr>
                <td><strong>Modelling and simulation after the trial:</strong> after the end of the trial, we will use the information we collected in an aggregate format in order to create models and simulators that will help us develop our products and services.</td>
                <td>Our <strong>legitimate interest</strong> to carry out statistical analysis and research in order to develop and improve our products and services.</td>
            </tr>
            <tr>
                <td><strong>Legal purposes:</strong> to protect our legal rights and where necessary, to establish, exercise or defend legal claims.</td>
                <td>Our <strong>legitimate interest</strong> to protect our legal rights and manage legal claims.</td>
            </tr>
        </table>
        <br />
        <p>We will not use your personal information for direct marketing purposes, and we will not use this information to take automated decisions about you which could have a legal effect or otherwise significantly affect you.</p>
        <p>When we rely on your consent to collect and process your personal information, you have the right to <b>withdraw your consent at any time</b> and stop participating in the trial. You can do so, by contacting us using the details under the “Contact us” section below.</p>
    </section>

    <section id="storing-your-personal-information" class="mt-10">
        <h3 class="font-bold text-l mb-4">4. Storing your personal information</h3>
        <p>We will store your personal information for the duration of the trial, and for January 2024 thereafter. After this period, we will aggregate and anonymise your personal information, so that the aggregate information no longer identifies you and does not constitute your personal information, unless there is a pending legal process for which we need to retain personal information.</p>
        <br />
        <p>We will deactivate your app account and collect the Alice hub and energy monitor when the trial is completed, or if you decide to withdraw your participation from the trial earlier, at that point.</p>
    </section>

    <section id="sharing-your-personal-information" class="mt-10">
        <h3 class="font-bold text-l mb-4">5. Sharing your personal information</h3>
        <p>We will share your personal information with our third party service providers, who will process it on our behalf for the purposes identified above, such as our IT service and hosting providers. We will also share personal information with the Catapult, which runs the Living Lab project and assists us with our trial (for example, Catapult will be carrying out meeting sessions on our behalf to collect feedback from the participants in our trial).</p>
        <br />
        <p>In order to use our app, you may be need to use third party services, such as the IFTTT (If This Then That) platform or SmartCar. These third parties have their own terms and privacy notices, and we advise you to read these before using their services.</p>
        <br />
        <p>We will share personal information with our professional advisors, such as legal advisors, and business consultants, if this is necessary for them to provide their services to us. We will remain responsible for how they use and store data.</p>
        <br />
        <p>In the event that our business is sold or integrated with another business, your details will be disclosed to our advisers and any prospective purchaser’s adviser and will be passed to the new owners of the business.</p>
        <br />
        <p>We will store personal information within the UK. If we need to store or transfer personal information to a country outside the UK and the European Economic Area, we will take measures to ensure that such transfer is in compliance with data protection laws, for example, ensure that it is made towards a country that is deemed to provide an adequate level of data protection, or towards a recipient who commits to UK-approved Standard Contractual Clauses, a set of contractual obligations which help provide appropriate data protection safeguards.</p>
    </section>

    <section id="your-data-protection-rights" class="mt-10">
        <h3 class="font-bold text-l mb-4">6. Your data protection rights</h3>
        <p>You have the right to <b>ask us for a copy</b> of the personal information we hold about you; to <b>correct</b> inaccurate or incomplete information, <b>delete</b> or <b>restrict</b> (stop any active) processing of your personal data; and to <b>obtain the personal data you provide to us with your consent in a structured, machine readable format</b> (e.g. excel or .csv file), and to ask us to <b>share (port) this data to another controller.</b></p>
        <br />
        <p>In addition, you can <b>object to the processing</b> of your personal information in some circumstances (in particular, where we rely on our legitimate interests to process your personal information).</p>
        <br />
        <p>These <b>rights may be limited,</b> for example if fulfilling your request would reveal personal data about another person or if you ask us to delete information which we may be required by law to keep. If an exemption applies, we will inform you of this when responding to any request you make.</p>
        <br />
        <p>To exercise any of these rights, you can get in touch with us using the details set out in the “Contact us” section below. If you have unresolved concerns, you have the <b>right to complain</b> to the data protection authority, which in the UK is the Information Commissioner’s Office.</p>
    </section>

    <section id="updates-to-this-notice" class="mt-10">
        <h3 class="font-bold text-l mb-4">7. Updates to this Notice</h3>
        <p>We may from time to time update this Notice if there is a change to the way we use personal data or if this is required by a legal or regulatory change. We will notify you of any new version of this Notice, for example by email.</p>
    </section>

    <section id="contact-us" class="mt-10">
        <h3 class="font-bold text-l mb-4">8. Contact us</h3>
        <p>If you have questions in relation to this Notice or on how we use your personal data at Amp X or if you would like to exercise your data protection rights, you can get in touch with us by writing to:</p>
        <br />
        <address>
            Amp X Limited<br />
            Suite 1, 3rd Floor 11 - 12 St. James's Square,<br />
            London, United Kingdom,<br />
            SW1Y 4LB
        </address>
        <br />
        <p>Or emailing us at: support[@]alice.energy</p>
    </section>








    </div>
    </div>
  );
}

export default Terms;
