import Benefits from './Components/Benefits';
import Hero from './Components/Hero';
import SupportedDevices from './Components/SupportedDevices'; 
import SavingsCharts from './Components/SavingsCharts';
import './index.css';
import SupportedBrands from './Components/SupportedBrands';
import FAQ from './Components/FAQ';
import UseCases from './Components/UseCases';


function App() {
  return (
    <div className="overflow-x-hidden">
      <Hero />
      <Benefits />
      <SupportedDevices />
      <UseCases />
      <SavingsCharts />
      <SupportedBrands />
      <FAQ />
    </div>

  );
}

export default App;
