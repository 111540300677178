import { ReactComponent as Logo } from '../Images/alice.svg';
import '../index.css';
import MobileMenu from './MobileMenu';
import TopMenu from './TopMenu';


function Header() {
  return (
  <div>
    <MobileMenu />
    <div className="w-full py-3 md:py-8 bg-black flex flex-col items-center px-2 text-white">
        <div className="w-full md:w-4/5 flex items-center justify-center md:justify-between">
          <div>
            <a className="hover:opacity-70" href="./"><Logo className="hidden md:block" width="120px" height="80px"/></a>
            <a className="hover:opacity-70" href="./"><Logo className="block md:hidden" width="80px" height="40px"/></a>
          </div>
          <TopMenu />
        </div>
    </div>
  </div>

  );
}

export default Header;
