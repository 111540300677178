import '../index.css';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';


function TroubleshootingContent() {
  return (
    <div className="flex flex-col items-center justify-center text-xs px-8 md:px-2 mt-4">
      <div className="text-xl md:text-xxl text-center font-thin">Troubleshooting</div>
      {/* <span className="w-full md:w-4/5 text-l font-bold p-2 text-left mt-2">How it works</span> */}
      <Accordion className="w-full md:w-4/5" allowZeroExpanded="true">

      <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Alice cannot connect to the Alice Hub
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>In order to complete this step, you will need to have your mobile phone to hand, as well as the Alice Hub and its power adapter.  You will also need to have the Alice App installed on your phone and to be logged in.</p><br />
                    <p> Before you plug in your Alice Hub to the mains, it's worth planning ahead and figuring out the best location in the house for you to place the Hub according to the following guidelines (shown in order of importance):</p><br />
                        <ul className="text-base list-disc list-inside">
                            <li>The Hub should be within 5 metres of your electricity meter;</li>
                            <li>The Hub should be close enough to your internet router such that it is a) possible to connect to the router with an Ethernet cable, or b) within WiFi range of the internet router;</li>
                            <li>All household appliances that you intend to connect to Alice should be within WiFi range of your internet router.  For most households, WiFi range is not an issue. If you have noticed that there are rooms in your house where the WiFi signal on your smartphone is weak and where such an appliance is present, then we recommend that you try to move your router a little closer to the room where the WiFi signal is weakest. </li>
                        </ul> 
                    <p></p><br />
                    <p>Once you have found the best location for your Alice Hub, make sure that the SD card is fully inserted into the Alice Hub, and plug the Hub into the mains.  If your internet router is close enough, use an Ethernet cable to connect the Hub to the router.</p><br />
                    <p>At this stage you are ready to open the app and follow the step-by-step instructions to connect the Alice Hub to your home network.  Make sure you are close enough to the Alice Hub for your phone to be able to connect to it via WiFi.  If Alice repeatedly fails to connect to the Hub or reports a Hub failure, please contact technical support.</p>
                    <p></p><br />
                    <p><a href="https://drive.google.com/file/d/1r6fdXLvzW1nSP2HQBOoHFIxNku23IKZt/view?usp=sharing" target="_blank">Click here for the installation manual</a></p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Alice can't connect a smart plug to the Alice Hub when adding a new device
                </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>Please, note that you should not use the Kasa application or any other applications to connect your smart plugs. You should follow the installation wizard in the Alice application.</p>
                    <p>The problem might be due to the smart plug not being within range of the Alice Hub. This may be due to a large distance or due to barriers between the smart plug and the Alice Hub. You can try moving the Alice Hub closer to the smart plug you’re having trouble with. Alternatively, you can plug the smart plug to a socket which is near the hub, go through the appliance onboarding process and, once successful, plug the smart plug into the socket where your appliance is currently plugged into.</p>
                    <p>If you're having trouble connecting a smart plug to the Alice Hub, we recommend resetting the smart plug to factory settings:</p><br />
                        <ul className="text-base list-disc list-inside">
                            <li>Make sure that the smart plug is plugged into a socket;</li>
                            <li>Press and hold the reset button until the Wi-Fi LED blinks amber rapidly (about 10 seconds) to reset the Smart switch to factory defaults.</li>
                        </ul> 
                    <p></p><br />
                    <p>Having reset the smart plug to factory defaults, plug the smart plug into a socket that is close to the Alice Hub and follow the instructions of the Add New Device wizard on the Alice app.</p><br />
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    I have trouble connecting the Alice Hub to the Alice app                </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>In order to connect the Alice Hub to the Alice app, the Hub will go into access point mode. In this state, the LED should be flashing yellow. Once the Hub is successfully connected, the LED will turn green.
If Alice has trouble connecting to the Hub, the LED will either be red or flashing red. In this instance, you will be able to restart the onboarding, or contact support.
If you restart the onboarding of the Hub, we advise you to reset it by pressing and holding the button on the Hub for 5-7 seconds. </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Why my wifi does not appear among the available networks list during the Alice Hub installation?                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>If your WiFi network is set to hidden, please, select custom network and fill in the credentials.</p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How do I reset the Alice Hub?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>To reset the Alice Hub, all you need to do is to press and hold the button on the Hub for 5-7 seconds. The LED will briefly turn red to indicate that you can stop holding it. Once you have reset it, the LED should be blinking yellow. After reset, you will have to reconnect the Alice Hub. To do so go to Settings>Connected Hardware>Alice Hub and select the “Reconnect Alice Hub” option.</p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    I have trouble connecting the Energy Monitor to the Alice app
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>The most challenging part of the Alice installation is the placement of a current clamp near your electricity meter. Please, follow the safety instructions in the user manual. If you have any trouble, please, contact support.You need to attach the current clamp to the insulated live-feed cable (usually brown) between your electricity meter and your consumer unit. This cable is usually on the right hand side of the meter. Do not attempt to attach the current clamp if any cabling is damaged. Do not attempt to modifythe cabling in any way. Please, follow the safety instructions in the user manual. </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    My appliance does not turn on manually
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>If your appliance does not turn on manually, this can be due to the fact that the smart plug is switched off.
You can switch the smart plug ON from the ON/OFF button located on the smart plug. You can also do this from the application: go to Settings>Connected Hardware>Smart Plug and, if the smart plug is in the “OFF” state, simply tap on the button to switch it ON.</p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    The Energy Monitor does not seem to measure power consumption
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>If you have placed the current clamp correctly and switched on the energy monitor, Alice should automatically detect the data coming from the energy monitor. If Alice is not able to receive any data, you will be alerted. If this is the case, please, contact support. </p>
                </AccordionItemPanel>
            </AccordionItem>

            {/* <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    My tariff is not available in the Alice app                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>If you tariff is not present in the list available in the “Select my tariff” screen of the app, please, select the “I don’t know my tariff” option. Alice will default to a flat tariff in order to calculate your savings. Please, contact support so that we will ensure your current tariff becomes available.</p>
                </AccordionItemPanel>
            </AccordionItem> */}

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    My smart plug loses its connection frequently
                </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>If you are consistently having trouble with a smart plug, it may be due to a large distance or due to barriers between the smart plug and the Alice Hub.   You can try moving the Alice Hub closer to the smart plug you’re having trouble with.  If the problem persists, please contact technical support.</p><br />
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    My electric vehicle does not start charging                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>Alice monitors the state and level of charge of your electric vehicle periodically (up to every 30 minutes). If you notice that your electric vehicle does not start charging, please, refresh the electric vehicle screen in the app, by tapping on the dedicated button. You can also select the “Boost” option to start charge immediately.If, after this step, the electric vehicle does not start charging, please, contact support.You can also disable Alice control of the charging by selecting the “monitor-only” option in the electric vehicle screen.  </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    My electric vehicle charge is not what I expected to be at the end of a charging session                   </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>During the first charges, Alice will be learning what the charge duration for your particular electric vehicle is. Initially, however, Alice utilises predicted charge durations. For this reason, there might be discrepancies between the level of charge that Alice has predicted and the actual one. If, after a few charges, the problem persists, please, contact support.</p>
                </AccordionItemPanel>
            </AccordionItem>

        </Accordion>
    </div>
  );
}

export default TroubleshootingContent;
