import './index.css';
import Header from './Components/Header';


function Download() {
  return (
    <div className="overflow-x-hidden flex flex-col h100 mb-60">
      <Header />
      <div className="flex flex-col items-center justify-center text-xs mt-10 mx-4 md:mx-40 lg:mx-80">
      <div className="text-xl md:text-xxl text-center font-thin">Download Alice App</div>
      <div className="w-full flex flex-col items-center md:flex-row mt-10 px-4">
        <a className="w-full md:w-1/2 md:mr-4" target="_blank" href="https://play.google.com/store/apps/details?id=energy.alice.app" alt="android">
          <img className="hidden md:block w-full" src="../Images/androiddesk.png" />
          <img className="block md:hidden w-full" src="../Images/android.png" />
        </a>
        <a className="w-full md:w-1/2 md:ml-4 mt-4 md:mt-0" target="_blank" href="https://apps.apple.com/us/app/alice-energy/id1575520474" alt="ios">
          <img className="hidden md:block w-full" src="../Images/iosdesk.png" />
          <img className="block md:hidden w-full" src="../Images/ios.png" />
        </a>
      </div>
      </div>    
    </div>
  );
}

export default Download;
