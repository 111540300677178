import '../index.css';

function UseCases() {
  return (
    <div className="w-full px-8 md:px-4 bg-white pt-4 pb-8 md:pt-10 md:pb-20 text-black flex flex-col items-center justify-center text-left">
      <div className="text-xl md:text-xxl text-center font-thin mx-4 leading-tight md:leading-none">Let Alice manage your energy usage</div>
      <div className="w-full md:w-2/5 mt-4 md:mt-10 mx-8 md:mx-0 flex flex-col md:flex-row items-center justify-around">
        <img className="w-full md:w-2/5 mx-4" src="../Images/alice-ui.jpg" />
        <div className="w-full md:w-3/5 flex flex-col justify-start wrap">
          {/* <span className="text-l p-2 mt-4 md:mt-0 text-center md:text-left">Let Alice manage your energy usage:</span> */}
          <span className="text-base font-bold bg-lightgrey p-4 mb-4 mt-2 md:mt-0 rounded-lg flex items-center">
            <img className="w-5 mr-3" src="../Images/calendar.svg" />
            No need to plan ahead, just plug in your electric vehicle or load your dishwasher
          </span>
          <span className="text-base font-bold bg-lightgrey p-4 mb-4 rounded-lg flex items-center">
            <img className="w-5 mr-3" src="../Images/brain.svg" />
            Alice will schedule your devices to use cheap, green electricity
          </span>
          <span className="text-base font-bold bg-lightgrey p-4 mb-4 rounded-lg flex items-center">
            <img className="w-5 mr-3" src="../Images/bell.svg" />
            Get notified in the app when they start and finish
          </span>
        </div>
      </div>
    </div>
  );
}

export default UseCases;
