import '../index.css';

function SupportedDevices() {
  return (
    <div className="bg-white py-10 md:py-20 text-black flex flex-col items-center justify-center">
      <div className="text-xl px-4 md:text-l text-center font-thin leading-tight md:leading-none">Supported device categories</div>
      <div className="w-full px-4 md:px-0 md:w-1/3/5 mt-4 md:mt-10 flex items-center justify-start md:justify-around horizontal-scroll md:overflow-hidden">
        <img className="w-1/4 md:w-1/12" src="../Images/Devices/washer.png" />
        <img className="w-1/4 md:w-1/12" src="../Images/Devices/tado.png" />
        <img className="w-1/4 md:w-1/12" src="../Images/Devices/dish.png" />
        <img className="w-1/4 md:w-1/12" src="../Images/Devices/sp.png" />
        <img className="w-1/4 md:w-1/12" src="../Images/Devices/ev.png" />
        <img className="w-1/4 md:w-1/12" src="../Images/Devices/pv.png" />
        <img className="w-1/4 md:w-1/12" src="../Images/Devices/battery.png" />
        <img className="w-1/4 md:w-1/12" src="../Images/Devices/dryer.png" />
        <img className="w-1/4 md:w-1/12" src="../Images/Devices/pump.png" />
      </div>        
    </div>
  );
}

export default SupportedDevices;
